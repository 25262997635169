.GoBackArrow
{
  cursor: pointer;
  position: fixed;
  padding: 5px;
  top:70%;
  background-color:var(--bgmain2);
  border:var(--bordermain);
  width: 50px;
  height: 50px;
  padding: .5rem;
  border-radius: .5vw;
  left: 20px;
}
.GoBackArrow:active{
  background-color: var(--highlightgreen);
}
.GoBackArrow svg
{
  height: 100%;
  width: 100%;
  margin: auto;
  left:0;
  bottom:0;
  position: absolute;
}
.SearchBackArrow path
{
  fill: var(--textmain1);
}