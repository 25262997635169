.Spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--highlightgreen);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  left: 45%;
  top: 45%;
  position: relative;
}
.WholePageSpinner
{
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--highlightgreen);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  left: 45%;
  top: 45%;
  position: absolute;
  z-index:999;
}
.fullpage
{
  height: 100vh;
  width: 100vw;
  background-color: var(--bg-color);
  position: fixed;
  overflow: hidden;
  z-index:998;
  top:0;
  left:0;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}