
html 
{
background-color: #dadce0;
margin-left: 0;
margin-top: 0;
margin-right: 0;
margin-bottom: 0;
padding:0;
font-family: Malgun Gothic, 'Garamond';
}


#landscape-message {
  background: white;
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.phone {
  height: 100px;
  width: 50px;
  margin-top: 10%;
  border: 3px solid black;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  /* display: none; */
}

.message {
  color: black;
  font-size: 1em;
  margin-top: 40px;
  text-align: center;
  /* display: none; */
}

@keyframes rotate {
  0% {
		transform: rotate(0deg)
	}
	50% {
		transform: rotate(-90deg)
	}
	100% {
		transform: rotate(-90deg)
	}
}



body{
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Malgun Gothic;
  src: url(fonts/malgun.woff2);
}

@font-face {
  font-family: Wingdings;
  src: url(fonts/wingding.woff2);
}

@font-face {
  font-family: Symbol;
  src: url(fonts/symbol.woff2);
}

