.slide {
    background-color: var(--bgmain2);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    margin: 10px;
    border: var(--bordermain);

    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
}


@media only screen and (orientation:landscape){
    .slide {
        width: 500px;
        height: 500px;
    }
}
.react-multi-carousel-track
{
    align-items: center;
}
.slide-text {
    display: flex;
    flex-direction: column;
    padding: 20px;
    text-align: left;
    color: var(--textmain1);
}

.slide-image {
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    object-fit: cover;
}

h2, p {
    margin: 0;
    padding: 0;
}

.slick-prev:before
{
    color: var(--hyperlinktextopposite);

}
.slick-next:before
{
    color: var(--hyperlinktextopposite);
}
.SliderSideText
{
    color: var(--textmain1);
    font-size: 2vh;
}