:root
{
  --ec_display--1: 84px;
  --ec_display--2: 65px;
  --ec_display--3: 46px;
  --ec_heading--1: 40px;
  --ec_heading--2: 32px;
  --ec_heading--3: 28px;
  --ec_heading--4: 24px;
  --ec_heading--5: 20px;
  --ec_heading--6: 18px;
  --ec_paragraph--1: 16px;
  --ec_paragraph--2: 14px;
  --ec_label--1: 13px;
  --ec_label--2: 12px;
  --ec_label--3: 10px;
}
:root,
:root.light {
  --bg-color: #e4e4e8;
  background-color: var(--bg-color);
  --Sph1-color: #080808;
  --PanelHeader-bg-color: #c2b8c7;
  --body-bg-color: #c2b8c7;
  --mainpage-bg-color: #dcd0e2;
  --PanelHeader-text-color: #2c1e33;
  --allmenu-theme-bg-color: #a8c6a4;
  --rightmenubtn-color : black;
  --cctopiccontent-bg-color : #f2f1f2;
  --cctopiccontent-text-color : #f2f1f2;
  --highlightcolor: rgb(73, 115, 81, 0.3);
  --logo-bg-color: #0e0e0e;
  --logo-text-color: #dedee3;
  --logo-button-hover: #1f1f1f;
  --logo-button-active: #4cbf6a;


  --bgmain2: #dedee3;
  --bgmain3: #d3d3d9;
  --bgopposite1: #0e0e0e;
  --bgopposite2: #1f1f1f;
  --bgopposite3: #333333;
  --textmain1:#0e0e0e;
  --textmain2: rgba(14,14,14,0.6);
  --textopposite1: #dedee3;
  --textopposite2: rgba(222,222,222,0.6);
  --textopposite3: rgba(222,222,222,0.4);
  --hovermain: #bdbdc8;
  --hoveropposite: #3d3d3d;
  --highlightgreen: #4cbf6a;
  --highlightgreentext:rgba(76,191,106,0.4);
  --bordermain: 1px solid #0e0e0e;
  --borderopposite: 1px solid #dedee3;
  --textongreen: #0e0e0e;
  --errortextred: #f47c7c;
  --hyperlinktext: blue;
  --hyperlinktextopposite: #4cbf6a;



}


:root.dark {
  --bg-color: #0e0e0e;
  background-color: var(--bg-color);
  --Sph1-color: white;
  --PanelHeader-bg-color: #080808;
  --body-bg-color: #080808;
  --mainpage-bg-color: #080808;
  --allmenu-theme-bg-color: #a8c6a4;
  --PanelHeader-text-color: white;
  --contentpage-text-color: white;
  --rightmenubtn-color : white;
  --cctopiccontent-bg-color : black;
  --highlightcolor: rgb(73, 115, 81, 0.7);
  --logo-bg-color: #e3e4e8;
  --logo-text-color: #0e0e0e;
  --logo-button-hover: #dedee3;
  --logo-button-active:  #4cbf6a;

  --bgmain2: #1f1f1f;
  --bgmain3: #333333;
  --bgopposite1: #e4e4e8;
  --bgopposite2: #dedee3;
  --bgopposite3: #d3d3d9;
  --textmain1:#dedee3;
  --textmain2: rgba(222,222,222,0.6);
  --textopposite1: #0e0e0e;
  --textopposite2: rgba(14,14,14,0.6);
  --textopposite3: rgba(14,14,14,0.4);
  --hovermain: #3d3d3d;
  --hoveropposite: #bdbdc8;
  --highlightgreen: #4cbf6a;
  --highlightgreentext:rgba(76,191,106,0.4);
  --bordermain: 1px solid #dedee3;
  --borderopposite: 1px solid #0e0e0e;
  --textongreen: #0e0e0e;
  --errortextred: #f47c7c;
  --hyperlinktext : #4cbf6a;
  --hyperlinktextopposite: blue;

  
}
/*
::selection {
  background: var(--hovermain);
  color: var(--textmain1);
  border-radius: 3px;
}

::-moz-selection { 
  background: var(--hovermain);
  color: var(--textmain1);
  border-radius: 3px;

}
// Not working in safari
::-webkit-selection {
  background: var(--hovermain);
  color: var(--textmain1);
  border-radius: 3px;
}*/

:root.dark #CCTopicContent span
{
  color: var(--contentpage-text-color);
}

.IconizedMenuWithLogoAndEaseMenu
{
  height:-webkit-fill-available;
  height: 100%;
  width: calc(100vw - 80px);
  overflow: auto;
  z-index: 992;
  position: fixed;
  left: 80px;
  background-color: var(--bg-color);
  top: 0;
  -webkit-overflow-scrolling: touch;
  min-width: 0;
}
.IconizedMenu
{
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  min-height: auto;
}
.icon-list {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
  padding: 30px;
  gap: 5%;
}

.wrapperIconizedMenuWithLogoAndEaseMenu
{
  display: flex;
  flex-direction: row;
  overflow: auto;
}


.non-exclusive-icon-buttons {
  display: flex;
  flex-direction: row;
  gap: 2vw;  
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 2vw;
}
.exclusive-icon-buttons {
  display: flex;
  gap: 2vw;  
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2vw;
}

.exclusive-button {
  padding: 1rem;
  height: 160px;
  width:160px;
  justify-content: center;
  border: var(--bordermain);
  border-radius: 0.5rem;
  background-color: var(--bgmain3);
  color: var(--textmain1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}
.exclusive-icons
{
  display: flex;
  flex-direction: column;
  border: var(--bordermain);
  border-radius: 10px;
  background-color: var(--bgmain2);
}
.non-exclusive-icons
{
  display: flex;
  flex-direction: column;
  border: var(--bordermain);
  border-radius: 10px;
  background-color: var(--bgmain2);
}
.svgIcons svg
{
  height: 90px;
  width: 90px;
}

.non-exclusive-button {
  padding: 1rem;
  border: var(--bordermain);
  border-radius: 0.5rem;
  background-color: var(--bgmain3);
  color: var(--textmain1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}

.exclusive-button:hover, .non-exclusive-button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.open-heading {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.5rem;
  text-transform: uppercase;
  color: var(--textmain1);

}

.ex-heading {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.5rem;
  text-transform: uppercase;
  color: var(--textmain1);

}



@media (max-width: 760px) {
  .non-exclusive-icon-buttons {
    margin-left: 1rem;
    }
    .exclusive-icon-buttons {
      margin-left: 1rem;
      }
  .open-heading {
    margin-left: 1rem;
  }
  .ex-heading {
    padding-left: 1rem;
  }
}

.IconTitle
{
  word-wrap:break-word;
  font-size: 1rem;
  text-align: center;
  color: var(--textmain1);
}

.logo {
  width: 100px;
  height: 100px;
  position: relative;
  top: 50px;
  left: 50px;
} 
@keyframes text-stroke-animation {
  0% {
    text-shadow: 0 0 5px var(--textmain1);
  }
  50% {
    text-shadow: 0 0 10px var(--textmain1), 0 0 15px var(--textmain1);
  }
  100% {
    text-shadow: 0 0 5px var(--textmain1);
  }
}
.text-stroke {
  font-size: 5rem;
  margin: 0;
  line-height: 110%;
  color: var(--highlightgreen);
  animation: text-stroke-animation 4s linear infinite;
}

svg#a path
{
  fill: var(--logo-bg-color);
}

svg#a rect{
  fill: var(--logo-bg-color);
}




.EaseMenu svg
{
  fill: var(--bgopposite1);
  right: 0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.EaseMenuHome svg
{
  fill: var(--bgopposite1);
  right: 0;
  bottom:0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}


button:disabled
{
  background-color: var(--bgmain2);
  border: var(--bordermain) ;
  cursor: not-allowed;
  color: var(--textmain1);
}

@media only screen and (orientation:portrait){
  .icon-list {
    flex-direction: column-reverse;
    margin-top: unset;
    gap: 10px;
  }
  body{
    height:100%;
  }
  .exclusive-icon-buttons, .non-exclusive-icon-buttons{
    flex-direction: column;
  }
  .exclusive-icons, .non-exclusive-icons
  {
    width:90vw;
  }
  .text-stroke{
    font-size: 3rem;
  }
  .EaseMenu svg
  {
    top: unset;
  }
}

.exclusivemainicons
{
  display: flex;
  flex-direction: column;
}

.otherexclusivemainicons
{
  border-top: dotted var(--bgopposite1) 4px;
  margin: 2vw;
  padding: 2vw;
}

.directContent
{
  height: unset;
  width: unset;
}


