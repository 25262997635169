.RevisionSchedulerButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .RevisionScheduler label{
    color: var(--textmain1);
  }
  .RevisionScheduler
  {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .RevisionSchedulerButtonsbutton {
    border: var(--bordermain);
    background-color: var(--bgmain2);
    color: var(--textmain1);
  }
  .HighlightRevisionSchedulerButton {
    background-color: var(--highlightgreen) !important;
    color: var(--textongreen);
  }
  .BoldRevisionSchedulerButton {
    color: var(--textmain1);
    font-weight: 600;
  }
  .BoldRevisionSchedulerButton::after
  {
    content: '*'
  }
  .RevisionSchedulerButtons button:hover {
    background-color: var(--hovermain);
  }
  .RevisionSchedulerTextBox input
  {
    width: 40px;
    margin-bottom:2px;
  }
.RevisionScheduler
{
   padding-bottom: 2%;
}
.lastRevision
{
  font-size: 10px;
  color: var(--textmain1);
  padding: 2px;
}

.gradLineAndLabel {
  padding: 2px;
  height: 20px;
  background: linear-gradient(to right, rgb(190, 58, 58) , #4cbf6a);
  width: 240px;
  cursor: none;
}
.RevisionBad
{
  font-size: 12px;
  float: left;
}
.RevisionGood
{
  font-size: 12px;
  float: right;
}