.SyllabusPage
{
 position: relative;
 font-family: Malgun Gothic,Garamond, serif;
 padding: 1%;
 margin:1% auto;
}
.SyllabusPanelControl
{
  top: 10%;
  position: inherit;
  padding:1vh;
}
.SPh1 {
  padding: 2%;
  margin:0%;
  font-weight: 600;
  font-size: 2em;
  text-transform: capitalize;
  color: var(--Sph1-color);
  font-style: normal;

}

.PanelHeader
{
  cursor: pointer;
  border: var(--bordermain);
  border-radius: 1vw;
  border-style: solid;
  background-color: var(--bgmain3);
  max-width: 80vw;
  padding: 15px;
  color: var(--textmain1);
}

.PanelHeader:hover
{
  background-color: var(--hovermain);
}
.PanelHeader:active
{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
}

.PanelContent
{
  border: none
}
.TopicList {
  position: relative;
  padding-left: 1%;
  height: 0.5%;
  line-height: 1.5em;
  margin: 1%; 
  width: fit-content;
  list-style: none;
  text-transform: capitalize;
  user-select: none;
  color: var(--textmain1);
}
.TopicListRevisionDate
{
  color: var(--textmain1);
  font-size: 0.5rem;
  float: right;
  padding-top: 3px; 
}


.SubjectGroupingButtons
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
  padding: 2%;
}
.SubjectGroupingButton
{
  border: var(--bordermain);
  background-color: var(--bgmain3);
  color: var(--textmain1);
  padding: 2%;
  border-radius: 0.5vw;
  cursor: pointer;
}
.SubjectGroupingButton:active{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
}
.SubjectGroupingButton:hover{
  background-color: var(--hovermain);
}
.TopicList:hover {
  font-weight: 700;
  text-decoration: underline;
}



.TopicsUl {
  padding: 2vw;
  margin: 0;
  list-style: none;
}

.activegroupingbutton
{
  background-color: var(--highlightgreen) !important;
  color: var(--textongreen) !important;
  outline: none;
}

.SubjectGroupingButton:focus{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
  outline: none;
}
.TopicListHighlight {
  padding-left: 30px !important;  /* adjust padding as needed */
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-user-select: text; /* Safari */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE/Edge */
  user-select: text;
  color: var(--textmain1);
}

.TopicListHighlight:hover {
  font-weight: normal ;
  cursor: text;

}

.TopicListHighlight em {
  font-weight: bold;
  font-style: normal;
}

.SearchResultHighlight
{
  display: flex;
  flex-direction: row;
  border: var(--bgopposite1) 4px;
  border-style: dotted;
  width: 100%;
}