.RevisionSchedulerList{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--bgopposite1);
    border-radius: 0.5rem;
}
.RevisionSchedulerListButton
{
    background-color: var(--bgopposite2);
    border: var(--borderopposite);
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
    color: var(--textopposite1);
    font-size: 1rem;
    user-select: none;
    cursor: pointer;
}
.RevisionSchedulerListButtons button{
    background-color: var(--bgopposite3);
    border: var(--borderopposite);
    color: var(--textopposite1);
    font-size: 0.5rem;
    cursor: pointer;
}
.RevisionSchedulerListButtons button:hover{
    background-color: var(--hoveropposite);

}

.RevisionSchedulerListButton:hover
{
    background-color: var(--hoveropposite);
}
.RevisionSchedulerListButtons
{
    display: flex;
    justify-content: flex-start;
    padding: 3px;
    gap: 1px;
}

.RevisionSchedulerListButtonSelection
{
    background-color: var(--highlightgreen) ! important;
    color: var(--textongreen)  !important;
}
.RevisionSchedulerListContent{
    background-color: var(--bgopposite1);
    display: flex;
    flex-direction: column;
}
.RevisionSchedulerListContentOnly
{
    overflow-y: auto;
    max-height: 7rem;
}
.RevisionSchedulerListContentOnly h1
{
    border: var(--borderopposite);
    border-radius:0.5vw;
    text-align: center;
    color: var(--textopposite1);
    font-size: 0.5rem;
    font-weight: 600;


}
.RevisionSchedulerListContentOnly li
{
    color: var(--textopposite1);
    font-size: 0.5rem;
    cursor: pointer;
}
.RevisionSchedulerListContentOnly p
{
    text-align: center;
    color: var(--textopposite1);
    font-size: 0.5rem;
}

.ClearBacklog
{
    align-self: flex-end;
    font-size: 0.5rem;
    margin-left: auto;
}

.ClearBacklog:focus
{
    background-color: var(--highlightgreen);
    color: var(--textongreen);
}

.WarningDiag
{
    top:30% ;
    left: 30% ;
    max-width: 50% ;
    max-height: 30% ;
    position: fixed;
}
.WarningDiag .ModalDiagBackground
{
    height: 100% ;
    width: 100% ;
    position: relative ;
}

.WarningDiag .ModalDiagBody
{
    white-space: unset;
    border: none;
    text-align: center;
}

.RevisionRescheduleHint
{
    color: var(--textopposite1);
    font-size: 0.5rem;
    text-align: center;
}