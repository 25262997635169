.BreakScreen
{
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: var(--bg-color);
    display: none;
    z-index: 1000;
    top: 0%;
    align-items: center;
    overflow: hidden;
}


@keyframes horizontal-shaking {
  0% { transform: translateX(0px); }
  25% { transform: translateX(-10px); }
  50% { transform: translateX(0px); }
  75% { transform: translateX(10px); }
  100% { transform: translateX(0px); }
 }

 .ErrorDisplayBreakScreen
{
  -webkit-animation: horizontal-shaking 0.3s linear 100;
-moz-animation: horizontal-shaking 0.3s linear 100;
-o-animation: horizontal-shaking 0.3s linear 100;
-ms-animation: horizontal-shaking 0.3s linear 100;
animation: horizontal-shaking 0.3s linear 100;
}

.breakScreenPwd
{
  align-self: end;
  margin-top: 1vh;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  background-color: var(--bgopposite2);
  color: var(--textopposite1);
}

.breakScreenPwd::placeholder
{
color: var(--textopposite2);
}

.submitArrow
{
  cursor: pointer;
  align-self: center;
  background-color: inherit;
  border: none;
}
.Displayshow
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.OtherThanBreakScreenLogo{
  background-color: var(--bgmain2);
  border: var(--bordermain);
  border-radius: 3vw;
  padding: max(10px, 2vw);
  display: flex;
  gap: 1vw;
  width: 85vw;
  min-height: 70vh;
}

.OtherThanBreakScreenMsgAndLogo
{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}
.BreakError
{
  padding: max(10px, 2vw);
  text-align: center;
  text-wrap: wrap;
  color:var(--errortextred);
  font-size: 2vw;
}
.BreakScreenMsg
{
  text-align: center;
  font-size: 2.2vw;
  padding: 2%;
  background-color:var(--bgmain3);
  border: var(--bordermain);
  color: var(--textmain1);
  font-weight: bold;
  border-radius:2vw;
  flex: 0 0 70%;
  display: flex;
  align-items: center;
}
.BreakContinue
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
  padding: 2vw;
  background-color: var(--bgmain3);
  border: var(--bordermain);
  border-radius: 2vw;
  font-weight: bold;
  flex-grow: 1;
  align-items: center;
}
.fa {
  color : var(--highlightgreen);
  padding: 1px;
}

.timer-wrapper svg 
{
  width: 100%;
  height: 100%;
}
.timer-wrapper div 
{
  font-size: 2vw;
}

.LogoInBreakScreen
{
  width: 10%;
}
.timer {
  font-size: 20px;
  text-wrap: 'wrap';
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: var(--textmain1);
}

.value {
  font-size: 3vw;
  color: var(--textmain1);
}

.submitArrow svg{
  margin-top: 1vh;
  margin-left: 1vw;
  fill: var(--bgopposite2);
}

@media only screen and (orientation:portrait){
  .OtherThanBreakScreenMsgAndLogo{
    flex-direction: row;
  }
  .OtherThanBreakScreenLogo{
    flex-direction: column;
    min-height: unset;
  }
  .BreakContinue{
    height: fit-content;
    margin-top: 10%;
    padding: 7%;
  }
}
