.Subscribe
{
  position: relative;
  text-align: center;
  word-wrap: break-word;
}
.Subscribe>span{
  animation: shake 0.5s infinite;
  font-size: 2rem;
  color: var(--contentpage-text-color);
  display: inline-block;

}
.Subscribe>span:hover
{
  animation:  none;
}

@keyframes shake {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  20% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

.SubscribeBtn
{
  background-color: var(--highlightgreen);
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(89, 244, 125, 0.4);
  box-shadow: 0 10px 30px 0 rgba(126, 248, 122, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.SubscribeBtn:active {
  box-shadow: 0 5px #cccccc;
  transform: translateY(3px);
}

/* Table Container */
.table-container {
  margin: 20px auto;
  padding: 50px 5px 50px 5px;
  background-color: none;
  width: 80%;
  overflow-x: auto;
}

.CenterStatus
{
  text-align: center !important;
}
.comparison-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Malgun Gothic, sans-serif;
}
.comparison-table th
{
  padding: 5px 5px;
  border: var(--bordermain);
}

.comparison-table td {
  padding: 10px 15px;
  border: var(--bordermain);
  text-align: left;
}

.comparison-table td {
  font-size: var(--ec_paragraph--2);
  color: var(--textmain1);
}
.comparison-table thead {
  background-color: var(--textmain1);
  color: var(--bgmain2);
  font-size: var(--ec_heading--6);
}

.comparison-table .section-heading {
  background-color: var(--bgmain2);
  font-weight: bold;
  text-align: center;
  font-size: var(--ec_heading--6);
}

.comparison-table h5 {
  margin: 0 0 5px 0;
  font-size: var(--ec_heading--5);
}


/* Status Icons */
.status {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
  align-items: center;
}

.available {
  background-color: var(--highlightgreen); /* Green */
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M9 16.2l-3.5-3.5L4 14l5 5L20 8.9 18.6 7.5z"/></svg>');
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M9 16.2l-3.5-3.5L4 14l5 5L20 8.9 18.6 7.5z"/></svg>');
  mask-size: cover;
  -webkit-mask-size: cover;
}

.not-available {
  background-color: var(--errortextred); /* Red */
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><line x1="6" y1="6" x2="18" y2="18" stroke="white" stroke-width="2" /><line x1="18" y1="6" x2="6" y2="18" stroke="white" stroke-width="2" /></svg>');
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><line x1="6" y1="6" x2="18" y2="18" stroke="white" stroke-width="2" /><line x1="18" y1="6" x2="6" y2="18" stroke="white" stroke-width="2" /></svg>');
  mask-size: cover;
  -webkit-mask-size: cover;
}




.SubscriptionWarning
{
  margin: 3%;
}



/* Responsive Design */
@media (max-width: 1000px), only screen and (orientation:portrait) {
  .table-container {
    margin: auto;
    width: 100%;
    padding: 0px;

  }
  .SubscriptionWarning
{
  margin: 2% 7% 2% 5%;
  width: 100%;
}
  .comparison-table th,
  .comparison-table td {
    padding: 2px;
    font-size: var(--ec_paragraph--1);
  }
}

