.MainPage
{
font-family: Malgun, 'Garamond' ;
padding: 4%;
margin: 7% 20px 7% 80px;
border: var(--bordermain);
border-radius: 1vw;
word-wrap: break-word;
background-color: var(--bgmain2);
}


.NoSelection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}