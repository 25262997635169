.ModalDiagBackground
{
width:100vw;
height:100vh;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
z-index: 999;
}
.ModalDiag
{
max-width: 80vw;
max-height: 70vh;
border-radius: 12px;
background-color: var(--bgopposite1);
box-shadow: rgba(0,0,0,0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
padding: 25px;    
}
.ContactUsContent
{
    display: flex;
    flex-direction: row;
    align-items: center;
}
a#Hyperlinks
{
    color: var(--hyperlinktextopposite);
    text-decoration: underline;
}
a#Hyperlinks:hover {
    color: var(--errortextred);
    text-decoration: none;
  }
a#Hyperlinks:visited {
    color: var(--hyperlinktextopposite);
    text-decoration: underline;
}
.ModalDiagTitle
{
display: inline-block;
text-align: center;
}
.ModalDiagTitleCloseButton
{
display: flex;
justify-content: flex-end;
}
.ModalDiagTitleCloseButton button
{
    cursor: pointer;
    border:none;
    background-color: transparent;
    font-size: 25px;
    color: var(--textopposite1);
    border: var(--borderopposite);
border-radius: 5px;
}
.ModalDiagTitleCloseButton button:hover
{
    background-color: var(--hoveropposite);
}
.ModalDiagBody
{
white-space: pre;
border: solid;
border-radius: 5px;
border-width: thin;
align-items: center;
font-size: 1.2rem;
overflow: auto;
color: var(--textopposite1);
}

.ModalDiagFooter
{
    text-align: center;
}
.ModalDiagTitleH1
{
    font-size: 1rem;
    color: var(--textopposite1);
}
.ModalDiagFooter button
{
    width: 150px;
    height: 45px;
    margin: 10px;
    border: var(--borderopposite);
    background-color: var(--bgopposite1);
    color: var(--textopposite1);
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

.ModalDiagFooter button:hover{
    background-color: var(--hoveropposite);
    color: var(--textopposite1);
}
.ModalDiagFooter button:active{
    background-color: var(--highlightgreen);
    color: var(--textongreen);
}