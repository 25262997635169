.ForgotPasswordPage{
  height: 100vh;
  width: 100vw;
  max-height: 120vh;
  max-width: 150vw;
  margin:0;
  padding:0;
  position: fixed;
  background-color: var(--bg-color);
  top:0;
  left:0;
}
.FPheading2
{
  color: var(--textmain1);
  text-align: center;
  font-size: 1rem;
}
.ForgotPasswordForm
{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ForgotPasswordPageh1 {

  text-align: center;
  color: #ffffff;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  }

  .Changepwdbtn
{
  background-color: var(--highlightgreen);
  border: none;
  color: var(--textmain1);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.Changepwdbtn:active {
  box-shadow: 0 5px #cccccc;
  transform: translateY(3px);
}
