.blurContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg-color);
    z-index: 1000;
  }
  
  .blurContainer img {
    max-width: 90%;
    max-height: 80%;
  }
  
  .blurContainer .message {
    color: var(--textmain1);
    font-size: 2rem;
    margin-top: 20px;
    animation: blink 2s infinite, pulse 2s infinite
  }

  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  @keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
  }
  
  