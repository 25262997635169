.comment-box {
    position: absolute;
    overflow: hidden;
    padding: 15px;
    background: var(--bgmain3);
    border: var(--bordermain);
    border-radius: 0.5vw;
    z-index: 991;
    display: none;
  }

  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 3px;
  }

 .header span
  {
    color: var(--textmain1);
    align-self: flex-start;
  }


  #CommentDeleteBtnSvg
  {
    width: 1em;
    height: 1em;
    top:0;
    bottom:0;
    left:0;
    margin: auto;
    cursor: pointer;
  }

  #CommentDeleteBtnSvg path
  {
    fill: var(--textmain1);
  }

  .deleteCommentBtn
  {
    border: var(--bordermain);
    border-radius: 0.5vw;
    background-color: var(--bgmain2);
    align-self: flex-end;
  }
  .deleteCommentBtn:hover
  {
    background-color: var(--hovermain);
  }
  .deleteCommentBtn:active
  {
    background-color: var(--highlightgreen);
  }
  .commentarea
  {
    resize: none;
    overflow: hidden;
    width:100%;
    background-color: var(--bgmain2);
    border-radius: 0.5vw;
    border: none;
    color: var(--textmain1);
  }
  .commentarea:focus
  {
    border: var(--bordermain) ;
  }

.textareaWithCount
{
  display: flex;
  flex-direction: column;
}
.hide
{
  display: none;
}

.updateCommentLen
{
  align-self: flex-end;
  color: var(--textmain1);
}