.App {
  width: 100%;
  height: 100%;
}

.OtherThanSideBar
{
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.AppPage
{
  display: flex;
  gap:20px;
  width: 100%;
}

.SideBarOnIconizedMenu
{
  background-color: var(--bg-color);
}