.CCform
{
  margin: auto;
  max-width: 30%;
  padding-top:1%;
  padding-bottom: 1%;
  max-height: 100vh;
  max-width: 100vw;
  z-index: 10;
  overflow: hidden;
}
.ClickableModalDiag
{
  cursor:pointer;
  color:var(--hyperlinktext);
  text-decoration:underline;
}

.ClickableModalDiag:hover {
  text-decoration:none;
  color: var(--errortextred);
}

#WhatsAppSvg
{
  fill: var(--bgmain2);
}

.Agreements
{
  display: flex;
  flex-direction: row;
}
.AgreementLabel
{
  font-size: 2vh;
  color: var(--textmain1);
}
.WholeLoginPage
{
  background-color: var(--bg-color);
  padding: 2% 1% 2% 1%;
}
.LoginLogowithBackground
{
  display: flex;
  flex-direction: column;

}
.Login{
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
  background-image: url('../images/Login_background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  border: var(--bordermain);
  margin:0;
  padding:0;
  top:0;
  left:0;
  margin-bottom: 40px;
}

#LogowithTitle
{
 width: 30vw;
 fill: var(--textmain1);
}

.LoginHeaderTabs
{
  display: flex;
  justify-content: space-evenly;
  height:auto;
}
.Formgroupr
{
  overflow: hidden;
  border-radius: 1vw 1vw 1vw 1vw;
  -webkit-border-radius: 1vw 1vw 1vw 1vw;
  background: var(--bgmain2);
  margin:0;
  width: 30%;
  padding:5px;
  top:25%;
  left:40%;
  position: absolute;
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;

}

.Formgroup
{
  overflow-y:auto;
  overflow-x: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.heading2 {
  color: var(--textmain1);
  text-align: center;
  font-size: 2vh;
  font-weight: 800;
  text-transform: uppercase;
  display:inline-block;
  width: 30%;
}
.Peptalk
{
  font-size: 0.7vw;
  font-weight: bold;
  padding: 4%;
  text-align: justify;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
  word-break: break-all;
}


.Formgroup::-webkit-scrollbar
{
	width: 1vw;
	background-color: #CAC2CF;
}

.Formgroup::-webkit-scrollbar-thumb
{
	border-radius: 0.5vw;
	-webkit-box-shadow: inset 0 0 1vw rgba(0,0,0,.3);
	background-color: var(--highlightgreen);
  background-clip: padding-box;  
}

.heading2.active {
  color: var(--textmain1);
  cursor: pointer;
  border-bottom: 0.5vh solid var(--highlightgreen);
}

.heading2.inactive {
  color: var(--textmain2);
  cursor: pointer;
}


::placeholder {
  color: var(--textopposite2);
  font-size: 60%;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control {
  background-color: var(--bgopposite2);
  border: none;
  color: var(--textopposite2);
  text-align: center;
   display: inline-block;
  font-size: 3vh;
  height: 6vh;
  width: 85%;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin:  1vh 1vh 1vh;
}
input:-webkit-autofill{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--textopposite2) !important;
    background: var(--bgopposite2) !important;
}
.form-control:focus
{
  outline: 2px solid #4cbf6a;

}
.form-control:disabled
{
  background-color: var(--bgopposite3);
}
.form-control:disabled::placeholder {
  color: var(--textopposite3); /* Gray color for placeholder text */
}

.formControlGroup
{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.LoginBtn
{
  color: var(--textongreen);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.5vh;
  -webkit-border-radius: 0.4vw 0.4vw 0.4vw 0.4vw;
  border-radius: 0.4vw 0.4vw 0.4vw 0.4vw;
  margin: 0.2vw 0.2vw 0.2vw 0.2vw;
  border: var(--bordermain);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: var(--highlightgreen);

}

.passwordInputWithRules
{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.passwordRules{
     column-count: 2;
     user-select: none;
    padding: 5px;
    font-size: 1.5vh;
    list-style: none;
    
    > li {
      display: flex;
      align-items: center;
      padding: 2px 0;
      color: rgba(var(--textmain1), .6);
      transition: .2s;
      
      &::before {
        content: '✔';
        display: inline-block;
        color: var(--textmain1);
        font-size: 1.5vh;
        line-height: 0;
        margin: 0 5px 0 0;
        transition: .2s;
      }
      
      &.pass{
        color:var(--textmain1);
        
        &::before {
          color: var(--highlightgreen);
          text-shadow: 0 0 8px currentcolor;
        }
      }
    }
}
.Slider
{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 30px 25px 30px;
}
.slick-dots
{
  position: relative ;
  bottom: 0px;
}
.slick-next
{
  color: var(--highlightgreen);
}
.LoginBtn:active {
  box-shadow: 0 5px #cccccc;
  transform: translateY(2px);
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: var(--highlightgreen);
  content: "";
  transition: width 0.2s;
}


.underlineHover:hover:after{
  width: 100%;
}
@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(3px) }
  50% { transform: translateX(-3px) }
  75% { transform: translateX(3px) }
  100% { transform: translateX(3px) }
 }
.ErrorDisplay{
  color: #ff000d;
  font-size:0.7em;
  padding: 0.5em;
  text-align: center;
}
.ErrorDisplayNoError
{
  color: #78a677;
  font-size:0.7em;
  padding: 0.5em;
  text-align: center;
}
.ErrorDisplayAnimate
{
  -webkit-animation: horizontal-shaking 0.5s 3;
-moz-animation: horizontal-shaking 0.5s 3;
-o-animation: horizontal-shaking 0.5s 3;
-ms-animation: horizontal-shaking 0.5s 3;
animation: horizontal-shaking 0.5s 3;
}


.LoginBtnWithSpinner
{
  display: felx;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ResetPassword
{
  color: var(--textmain1);
  font-size: 2vh;  
  bottom: 2%;
  right: 7%;
  padding: 2vh;
  cursor: pointer;
}
.ResetPassword:active{
  color: var(--highlightgreen);
}
/*
.ProductsOffered
 {
  width: 21%;
  padding:0;
  top:21vh;
  left:1%;
  height:46vh;
  font-size: 2vh;
  text-align: justify;
  position: fixed;
 

}


.ProductsOfferedText {
  height: 100%;
  text-align: center;
  list-style-type: none;
  line-height: 5vh;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;


}

.ProductsList
{
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
-moz-animation: scroll-animation 5s linear infinite;
-webkit-animation: scroll-animation 5s linear infinite;
animation: scroll-animation 5s linear infinite;
animation-direction: normal;
animation-fill-mode: both;
  white-space: nowrap;

}*/
/* for Firefox */
@-moz-keyframes scroll-animation {
  0% { -moz-transform: translateY(800%); }
  25% { -moz-transform: translateY(400%); }
  50% { -moz-transform: translateY(0%); }
  75% { -moz-transform: translateY(-400%); }
  100%{
    -moz-transform: translateY(-800%); 
  }
}

/* for Chrome */
@-webkit-keyframes scroll-animation {
  0% { -moz-transform: translateY(800%); }
  25% { -moz-transform: translateY(400%); }
  50% { -moz-transform: translateY(0%); }
  75% { -moz-transform: translateY(-400%); }
  100%{
    -moz-transform: translateY(-800%); 
  }
}

@keyframes scroll-animation {
  0% {
    -moz-transform: translateY(800%);
    -webkit-transform: translateY(800%);
    transform: translateY(800%);
  }
  25% {
    -moz-transform: translateY(400%);
    -webkit-transform: translateY(400%);
    transform: translateY(400%);
  }
  50% {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  75% {
    -moz-transform: translateY(-400%);
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
  100% {
    -moz-transform: translateY(-800%);
    -webkit-transform: translateY(-800%);
    transform: translateY(-800%);
  }
}

.AboutUsWhatWeOffer
{
  display: flex;
  flex-direction: row;
  gap: 50px;

}
.AboutUs
{
display: flex;
flex-direction: column;
flex: 1 1 0px;
border-radius: 10px;
border: var(--bordermain);
background: var(--bgmain2);
padding-bottom: 0.5rem;
}
.AboutUsHeading
{
  color: var(--textmain1);
  text-align: center;
  padding: 1vh 2vw 1vh 2vw;
  font-weight: 600;
  font-size: 1.2em;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.WhatWeOfferHeading
{
  color: var(--textmain1);
  text-align: center;
  padding: 1vh 2vw 1vh 2vw;
  font-weight: 600;
  font-size: 1.2em;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.WhatWeOffer
{
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  border-radius: 10px;
  border: var(--bordermain);
  background: var(--bgmain2);
padding-bottom: 0.5rem;
}

.AboutUsText
{
  color: var(--textmain1);
  padding: 1vh 2vw 1vh 2vw;
  justify-content: center;
  text-align: justify;
  white-space: pre-wrap;
  hyphens: auto;
}

.WhatWeOfferText
{
  color: var(--textmain1);
  padding: 1vh 2vw 1vh 2vw;
  justify-content: center;
  text-align: justify;
  white-space: pre-wrap;
  hyphens: auto;
}

.OurPhilosophy
{
  border: var(--bordermain);
  background: var(--bgmain2);
    border-radius: 10px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    flex-grow: 1;
    pointer-events: none;
    padding-top: 0.5rem;
}
.OurPhilosophyUnit
{
  display: flex;
  gap: 2%;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: row;
}

.OurPhilosophyHeading
{
  color: var(--textmain1);
  text-align: left;
  font-weight: 600;
  font-size: 1.2em;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  margin-bottom: 10px;

}

.OurPhilosophyText
{
  color: var(--textmain1);
  justify-content: center;
  text-align: justify;
  white-space: pre-wrap;
  hyphens: auto;
  margin-bottom: 10px;
}
.LoginUpperPageMenu
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}
.LoginPotrait
{
  display: none;
}
.OthersMenu
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1vh 1vw 1vh 1vw;
  gap: 2%;
  text-align: center;
}
.ReachOutToUs, .PrivacyPolicy, .TermsAndConditions
{
  border: none;
  border-radius: 10px;
  background-color: var(--bgopposite1);
  color: var(--textopposite1);
  flex-grow: 1;
  padding: 0.5rem;
  font-size: 0.9rem;
}
.ReachOutToUs:active, .PrivacyPolicy:active, .TermsAndConditions:active{
  background-color: #4cbf6a;
  color: #0e0e0e;
}
.hoverable
{
  cursor: pointer;
}

.indenttext
{
  text-align: justify;
  margin-left: 3em;
}

@media only screen and (orientation:portrait){
  .AboutUsWhatWeOffer
  {
    flex-direction: column;
  }
  .LoginPotrait
  {
    display: block;
    background-image: url('../images/Login_background.svg');
    background-repeat: no-repeat;    
    height:26vh;
    width: 100%;
    border: var(--bordermain);
    margin:0;
    padding:0;
    background-size: cover;
    top:0;
    left:0;
    margin-bottom: 40px;
    border-radius: 1vw;
  }
  .Login{
    display: flex;
    background-image: unset;
    background-repeat: unset;  
    flex-direction: column;
    border: var(--bordermain);
    height: auto;
  }
  .CCform
  {
    overflow: unset;
    margin: unset;
  max-width: unset;
  padding-top:1%;
  padding-bottom: 1%;
  max-height: unset;
  max-width: unset;    
  }
  .Formgroupr
  {
    height: auto;
    overflow: hidden;
    position: static;
    width:auto;
    background: unset;
    box-shadow: unset;
  }
  .ErrorDisplay, .ErrorDisplayNoError
  {
    font-size: 1rem;
  }
}