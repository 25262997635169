/*:root,
:root.light {
  --bg-color: #fff;
  --text-color: #123;
}

:root.dark {
  --bg-color: #121212;
  --text-color: #45ADFE;
}

body {
  margin: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Inter', sans-serif
}
*/

.DarkToggleSvg
{
    position: relative;
    height: 20px;
    width:20px;
    top: 2%;
    left:-15%;
}

.DarkToggle {
  position: relative;
}

.theme-btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
}

.theme-btn:active {
  background: var(--highlightgreen);
  color: var(--textongreen);
}

.theme-btn:hover
{
background-color: var(--hoveropposite);
}
.DarkToggleSvg svg
{
 fill: var(--textopposite1);
}
