.ContentPage{
  width: 100%;
  height: 100%;
}

.ContentFrame
{
  position:relative; 
  top:0; 
  left:0; 
  bottom:0; 
  right:0; 
  width:100%; 
  height:100%; 
  border:none; 
  margin:0; 
  padding:0; 
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
.CPSideBarWithButton 
{
  position: fixed;
  width: 30%;
  right: -30%;
  height: 100%;
  z-index: 989;
  background: var(--bg-color);
  color: var(--textmain1);
  transition: all 0.5s;
  box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
  border-radius: 1em;
  margin:0%;
  top:1%;
}
.ContentPageSidebar
{
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.CPSideBar
{
font-family: Malgun Gothic, Garamond, serif;
height: 100%;

}

.CPSidebaractive
{
  right:0; 
}
.CPSidebarinactive
{
  right:-30%; 
}
.ContentSBbtn
{
text-align: center;
position: absolute;
left: -10%;
width: 3%;
height: 5%;
padding-top: 2px;
cursor: pointer;
border: none;
z-index: 995;
}

.arrowleft {
  border: solid var(--bgopposite1);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  
}
.arrowright {
  border: solid var(--bgopposite1);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  
}
.arrowleft:hover
{
  transition:  transform 0.5s linear;
  -webkit-transition:  transform 0.5s linear;
  -moz-transition: transform 0.5s linear;
  -o-transition: transform 0.5s linear;
  -ms-transition: transform 0.5s linear;
  transform: translate(-5px,0) rotate(135deg);
  -webkit-transform: translate(-5px,0) rotate(135deg);
  -moz-transform: translate(-5px,0) rotate(135deg);
  -o-transform: translate(-5px,0) rotate(135deg);
  -ms-transform: translate(-5px,0) rotate(135deg);
}
.arrowright:hover
{
  transition:  transform 0.5s linear;
  -webkit-transition:  transform 0.5s linear;
  -moz-transition:  transform 0.5s linear;
  -o-transition:  transform 0.5s linear;
  -ms-transition:  transform 0.5s linear;
  transform: translate(5px,0px) rotate(-45deg);
  -webkit-transform: translate(5px,0px) rotate(-45deg);
  -moz-transform: translate(5px,0) rotate(-45deg);
  -o-transform: translate(5px,0) rotate(-45deg);
  -ms-transform: translate(5px,0) rotate(-45deg);
}
.CPSidebarMenuItem
{
  border: var(--bordermain);
  border-radius: 0.5vw;
  background-color: var(--bgmain3);
}
/* width */
nav::-webkit-scrollbar {
  width: 0.5vw;
}


/* Track */
nav::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
nav::-webkit-scrollbar-thumb {
  background: var(--hovermain);
}

/* Handle on hover */
nav::-webkit-scrollbar-thumb:hover {
  background: var(--highlightgreen);
}
* 
{ -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box;
   box-sizing: border-box; }

*, ::after, ::before {
  box-sizing: border-box;
}


.CM {
  position: fixed;
  left: 0;
  top:0;
  z-index: 990;
  background: none;
}

.CMItem
{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 1;
  background: var(--bgopposite1);
  color: var(--textopposite1);
  border-radius: 0.5vw;
  border-style: var(--bordermain);
  padding: 4px 6px;
}
#HighlightSvg
{
  width: 1em;
  height: 1em;
  fill: var(--textopposite1);
  top:0;
  bottom:0;
  left:0;
  margin: auto;
}
#CommentSvg
{
  width: 1em;
  height: 1em;
  fill: var(--textopposite1);
  top:0;
  bottom:0;
  left:0;
  margin: auto;
}
.HighlightOptionText
{
  color: var(--textopposite1);
  font-size: 1em;
}
@media (hover: hover)
 {
  .CMItem:hover {
    background: var(--hoveropposite); }
}


.Highlight
{
  background-color:var(--highlightgreen);
  cursor: pointer;
  border-radius: 3px;
  color: var(--textongreen) !important;
}
#CCTopicContentPDF2HTML .Highlight
{
  background-color:var(--highlightgreentext);
  cursor: pointer;
  border-radius: 3px;
}
#CCTopicContentPDF2HTML .Comment
{
  background-color:var(--highlightgreentext);
  cursor: pointer;
  border-radius: 3px;
}

#CCTopicContent .Comment
{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
  cursor: pointer;
  border: var(--bordermain);
  border-width: 2px;
  border-radius: 3px;
  color: var(--textongreen)!important;
}

#CCTopicContent .Comment.showcommentpin::before {
  content: "\1F4CC"; 
}

.Underline
{
  text-decoration: underline;
}

.ContentSBTabBtn:hover {
  background-color: var(--hovermain);
  color:var(--textmain1);
}
.ContentSBTab
{
  height: 100%;
  border: var(--bordermain);
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
}

.ContentSBTabBtn
{
  border: var(--bordermain);
  background-color: var(--bgmain2);
  cursor: pointer;
  color: var(--textmain1);
  padding: 2%;
  transition: 0.3s; 
  font-size: 1.75vw;
  resize: horizontal;
  border-radius: 0.5vw;
}
.ContentSBTabBtnActive
{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
  outline: none;
}
.ContentSBTabBtn:focus{
  background-color: var(--highlightgreen);
  color: var(--textongreen);
  outline: none;
}

.ContentSBTabButtons 
{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap:10%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 2%;

}
.separatorafter {
  content: "";
  display: block;
  height: 1px;
  background-color: var(--textmain1);
  align-self: center;
  width: 85%;
  padding: 1px;
  margin-bottom: 5px;
}

.ContentSBTBContentMaster
{
  height:90%;
  padding: 2%;
}
.SecondCBTab
{
  display:flex;
  flex-direction:column;
  height:80%;
}
.ContentSBTBContent
{
  display:flex;
  flex-direction:column;
  height:95%;

}
.FullScreenRNL
{
  position: relative;
  display: inline;
  align-self: center;
  color: var(--textmain1);
}
.ContentSBTBContentRE
{
  height:90%;
}
.EditBoxStyle
{
  position:relative;
  overflow:auto;
}

button.rdw-image-modal-btn
{
  width:fit-content;
}


.FullScreenRN
{
  color: var(--textmain1);
  position:relative;
  display:flex;
  flex-direction: row;
  padding: 1vh;
  justify-content: flex-end;
  font-size: 3vh;
}


.ToggleFullScreen {
  position: relative;
  align-self: center;
  width: 40px;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  background: grey;
  outline: none;
  border-radius: 1rem;
  cursor: pointer;
}

.ToggleFullScreen::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.ToggleFullScreen:checked::before {
  transform: translateX(100%);
  background: #fff;
}

.ToggleFullScreen:checked {
  background: var(--highlightgreen);
}





.ContentSBTBContentRETab
{
  height: 80vh;
  width:85%;
  position: relative;
}

.FullScreenRNTab
{
  color: var(--bg-color);
  display:flex;
  flex-direction: row;
  z-index: 997;

}

.fullScreenTab {
  position: fixed;
  top: 0%;
  left: 0%;
  background: var(--bg-color);
  display:flex;
  flex-direction:column;
  align-items: center;
  width:100vw;
  height: 100vh;
  z-index: 997;  
  gap: 5%;
}

#CCTopicContent
{
font-family: Malgun, 'Garamond' ;
overflow-x: auto;
width: 85%;
min-height: 297mm;
padding: 4%;
padding-bottom: 1%;
margin: 7% 10px 7% 80px;
margin-bottom: 1%;
border: var(--bordermain);
border-radius: 1vw;
background: var(--bgmain2);
box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
word-wrap: break-word;
}
#CCTopicContentPDF2HTML
{
  overflow-x: auto;
width: auto;
min-height: 297mm;
padding: 4%;
margin: 7% 7% auto;
word-wrap: break-word;
}
.subheading
{
font-size: 0.9rem;
padding: 1% 1% 1% 7%;
display: flex;
cursor: pointer;  
justify-content: flex-end;
position: relative;
border-radius: 1vw;
flex-direction: row-reverse;
}

.subheading:hover, focus {
  font-weight: 700;
  text-decoration: underline;
}
.subheading:active
{
  text-decoration: underline;
}


.bigheading
{
font-size: 1rem;
padding: 1% 3% 1% 3%;
display: flex;
cursor: pointer;  
position: relative;
border-radius: 0.5vw;
}


.bigheading:hover, focus {
    background: var(--hovermain);
}
.subsubheading
{
  font-size: 0.8rem;
  padding: 1% 1% 1% 14%;
  display: flex;
  cursor: pointer;  
  justify-content: flex-end;
  position: relative;
  border-radius: 1vw;
  flex-direction: row-reverse;
}

.subsubheading:hover, focus {
  color: var(--bg-color);
    background: rgb(0, 0, 0);
}


.Nextbutton
{
  cursor: pointer;
  color: var(--rightmenubtn-color);
}

.Prevbutton
{
  cursor: pointer;
  color: var(--rightmenubtn-color);
}
.NextPrevGroup
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 50px;

}
.imageViewer
{
  position: fixed;
  height: 100vh;
  width:100vw;
  top:0;
  left:0;
  right:0;
  overflow:hidden;
  background-color: var(--bg-color);
  z-index: 993;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.closeImageViewerbtn{
  cursor: pointer;
    border:none;
    background-color: var(--bg-color);
    font-size: 25px;
    color: var(--textmain1);
    border: var(--bordermain);
    border-radius: 5px;
    align-self: flex-start;
    margin-left: 40px;
    position:absolute;
    top:5%;
    z-index:991;
}
.imageViewer div div{
  background-color: var(--bgmain2);
  right: 40px !important;
  z-index: 991!important;

}
.contextMenu
{
  display: flex;
  flex-direction: row;
  flex: 1 1 0px !important;
  z-index: 991;
  background-color: var(--bgopposite1);
  border: var(--bordermain);
  border-radius: 0.5vw;
  transform: translateX(-50%);
}
.contextMenu:after{
  content: "";
  position: absolute;
  left: 41%;
  top: -28%;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid var(--bgopposite1);
  z-index: 989;
}

.imageViewer div div:active
{
  background-color:var(--highlightgreen);
}
.imageViewer svg{
  stroke: var(--textmain1) ! important;
}
.imageViewer svg path{
  stroke: var(--textmain1) ! important;
}
.imageViewer svg polygon{
  stroke: var(--textmain1) ! important;
}
#CCTopicContent img{
  cursor: pointer;
  border: var(--bordermain);
  border-radius: 5px;
  border-width:3px;
}

.pan-container, .pan-container div
{
  background-color: var(--bg-color) !important;
}
.pan-container img{
  max-width: 95vw;
  height: auto;
  width: auto;
  max-height: 80vh;
  object-fit: contain;
}

.UseLandscapeAdvice{

  background: var(--bgopposite1);
  color: var(--textopposite1);
  position: fixed;
  top: 45%;
  left: 5%;
  border: var(--bordermain);
  border-radius: 1vw;
  font-size: 3vh;
  padding: 10px;
  animation: fadeOut 7s forwards;
  z-index: 996;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (orientation:portrait){
  .CPSideBarWithButton 
  {display: none;}
  p[class^="CivilsCode"] {
    margin-left: 0 ! important;
    text-indent: 0 ! important;
    line-height: 20pt ! important;
    hyphens:auto ! important;
    }
    span.CivilsCodeDefaultParagraphFont{
      font-size: 28pt ! important;
      }

        #CCTopicContent h3, #CCTopicContent h2{
          display: flex !important;
          flex-direction: row !important;
          text-indent: 0px ! important;
          margin-left: 0px ! important;
          text-align: left !important;
        }
}

.SearchHighlight {
  background-color: #f5f08e;
  color: var(--textongreen) ! important;
  transition: background-color 2s ease;
}

#CCTopicContent div[class^='CivilsCode000']
{
  border-color: var(--textmain1);
}

.ql-container {
  box-sizing: border-box;
  font-family: "Malgun Gothic", sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0;
  position:relative;
}

.ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6, .ql-editor ol, .ql-editor p, .ql-editor pre, .ql-editor ul {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding:0;
  color: var(--textmain1);
}

.ql-editor ul[data-checked=false] > li:before, .ql-editor ul[data-checked=true] > li:before {
  color: var(--textmain1);
  cursor: pointer;
  pointer-events:all
}

.ql-editor.ql-blank:before {
  color: var(--textmain2);
  content: attr(data-placeholder);
  font-style: normal;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right:15px
}

.ql-snow .ql-toolbar button, .ql-snow.ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 1.5rem;
  width:1.5rem;
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar button.ql-active, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button:hover {
  color: var(--highlightgreen);
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill {
  fill: var(--highlightgreen);
}

.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: var(--highlightgreen);
}

@media (pointer: coarse) {
  .ql-snow .ql-toolbar button:hover:not(.ql-active), .ql-snow.ql-toolbar button:hover:not(.ql-active) {
      color: var(--textmain1)
  }

  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
      fill: var(--textmain1)
  }

  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke, .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
      stroke: var(--textmain1)
  }
}

.ql-snow .ql-stroke {
  fill: none;
  stroke: var(--textmain1);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width:2
}

.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: var(--textmain1);
  stroke-miterlimit: 10;
  stroke-width:2
}

.ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
  fill: var(--textmain1);
}

.ql-snow .ql-picker.ql-font {
  width:108px;
  display: none;
}

.ql-snow .ql-picker.ql-size {
  width:98px;
  display: none;
}


.ql-toolbar.ql-snow {
  width: 100%;
  border: var(--bordermain);
  box-sizing: border-box;
  font-family: "Malgun Gothic", sans-serif;
  padding:8px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px;
}


.ql-toolbar.ql-snow + .ql-container.ql-snow {
  width: 100%;
  border-top:var(--bordermain);
}

.ql-snow a {
  color:#4cbf6a;
}

.ql-container.ql-snow {
  width:100%;
  border: var(--bordermain);
  border-radius: 7.5px;
  background-color: var(--bgmain3);
  flex-grow: 1;
}

.quill_wrapper{
  height: 100%;
  flex-grow: 1;
  margin-bottom: 2vh;
}
.quill{
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2vh;
}
.ql-toolbar{
  border: var(--bordermain);
  border-radius: 7.5px;
  height: auto;
  padding: 2vh;
  background-color: var(--hovermain);
}
.ql-formats button{
  border: none;
  background-color: var(--hovermain);
}
.ql-container ql-snow{
 
}


[class="ql-header"][value="1"]{
 
}
[class="ql-header"][value="2"]{

}
[class="ql-list"][value="ordered"]{

}
[class="ql-list"][value="bullet"]{

}
.ql-underline{
  display: none !important;
}
.ql-code-block{
  display: none !important;
}
.ql-link{
  display: none !important;
}
.ql-size{
  display: none !important;
}
.ql-color{
  display: none !important;
}
.ql-font{
  display: none !important;
}
.ql-align{
  display: none !important;
}

.ql-snow .ql-editor blockquote{
  border-left: 4px solid var(--textmain1);
  }

  .ql-snow .ql-color-picker .ql-picker-options
  {
    width: unset;
  }

  .ql-snow .ql-picker-options
  {
    background-color: var(--bgopposite1);
  }

  @media only print {
    body {
      display: none !important;
      visibility: hidden !important;
    }
    #CCTopicContent
    {
      display: none !important;
      visibility: hidden !important;
    }
    #CCTopicContentPDF2HTML{
      display: none !important;
      visibility: hidden !important;
    }
  }

  p {
    -webkit-user-select: text !important;
    user-select: text !important;
   }
   h1 {
    -webkit-user-select: text !important;
    user-select: text !important;
   }
   h2 {
    -webkit-user-select: text !important;
    user-select: text !important;
   }
   h3 {
    -webkit-user-select: text !important;
    user-select: text !important;
   }
   h4 {
    -webkit-user-select: text !important;
    user-select: text !important;
   }
   h5 {
    -webkit-user-select: text !important;
    user-select: text !important;
   }
   h6 {
    -webkit-user-select: text !important;
    user-select: text !important;
   }

